import React from "react"
import { graphql } from "gatsby"
import Layout from "@/components/Layout"
import { buildBlocks } from "@/services/blockGenerator"
import { ButtonLink } from "@/components/reusable/Button"

type PageProps = {
  data: {
    wpPage: Queries.WpPage
    wp: Pick<Queries.Wp, "themeOptions"> & {
      themeOptions: Pick<Queries.WpThemeOptions, "ThemeOptions"> & {
        ThemeOptions: Pick<Queries.WpThemeOptions_Themeoptions, "siteLogo"> & {
          siteLogo: Pick<Queries.WpMediaItem, "mediaItemUrl">
        }
      }
    }
  }
  pageContext: {
    pagePath: string
    pageTitle: string
  }
}

export default function Page ({ data, pageContext }: PageProps) {
  if (!data) {
    console.error("No data found for page")
    return null
  }

  pageContext.pageTitle = data.wpPage.Meta?.metaTitle
    ? data.wpPage.Meta.metaTitle
    : data.wpPage.title

  const generatedBlocks = buildBlocks(data.wpPage.blocks, {}, pageContext)

  return (
    <Layout
      meta={data.wpPage.Meta}
      path={pageContext.pagePath}
      title={data.wpPage.title}
      themeOptions={data.wp.themeOptions}
      displayMenu={data.wpPage.displayMenu}
    >
      {generatedBlocks}
      {data.wpPage.floatingButton?.showButton && (
        <div
          className={`floating-button fixed bottom-4 right-6 z-10 [&_.brew-button]:m-0`}
        >
          <ButtonLink
            link={data.wpPage.floatingButton?.buttonLink?.url ?? ""}
            variant={`primary`}
          >
            {data.wpPage.floatingButton?.buttonLink?.title}
          </ButtonLink>
        </div>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage {
          uri
        }
      }
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      floatingButton {
        showButton
        buttonLink {
          title
          url
        }
      }
      displayMenu {
        utilityNav
        mainMenu
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

